import logo from './logo.png';
import './App.scss';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const theme = createMuiTheme({ 
  palette: {
    primary: {
      light: '#757ce8',
      main: '#0081af',
      dark: '#008fc2',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

const useStyles = makeStyles(theme => ({
  buttonProgress: {
    color: '#0081af',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}))

function App() {
  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);
  const [ step, setStep ] = useState(0);
  const [ error, setError ] = useState(null);

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    fetch("https://wapi.ktbc.org.hk/congregations/", {
      method: "POST",
      body: `n=${encodeURIComponent(document.getElementById("name").value)}&p=${encodeURIComponent(document.getElementById("phone").value)}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.success === true) {
        setStep(1);
      }
    })
    .finally(() => {
      if (step === 0) {
        setError("暫時無法建立QR Code，請稍後再嘗試。如再無法建立，請聯絡我們並詳述你遭遇此問題的原因。");
        setLoading(false);
      }
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <header>
          <img src={logo} alt="觀塘浸信會圖標" />
        </header>
        <div className="divider"></div>
        {
          step === 0 ? (
            <section>
              <h1>索取個人二維碼（QR Code）表格</h1>
              <p>
                這是製作「觀塘浸信會個人 QR Code」的申請表，教會因應政府的防疫要求作進入處所登記之用。<br />
                用者只需要在進場時用手機展示自己的「觀塘浸信會個人 QR Code」，即可完成登記。<br /><br />
                請填妥以下的資料並提交，教會稍後將會用教牧電話給您送上你的「觀塘浸信會個人 QR Code」。
              </p>
              <form action="/" method="POST" onSubmit={handleSubmit}>
                <label htmlFor="name">姓名（中文全名）：</label>
                <TextField fullWidth={true} id="name" name="n" required />
                <label htmlFor="phone">聯絡電話：</label>
                <TextField fullWidth={true} id="phone" name="p" required inputProps={{pattern: "[0-9]{8}"}} />
                <br />
                <br />
                <div className="disclaimer">
                  <div>收集個人資料聲明：</div>
                  閣下提供的個人資料只用作防疫措施安排、聯絡通訊及統計之用。只有本堂授權的同工及義工方可處理閣下的個人資料，除因公共衛生原因被要求交予衛生防護中心外，閣下之資料會保留31天後銷毀。
                </div>
                <br />
                <div className="button-wrapper">
                  <Button type="submit" variant="contained" color="primary" disabled={loading}>提交</Button>
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
                {error && <div className="error">{error}</div>}
              </form>
            </section>
          ) : (
            <section style={{textAlign: "center"}}>
              <h1>完成</h1>
              <p>你的「觀塘浸信會個人 QR Code」已經發送到你的 WhatsApp 中。</p>
            </section>
          )
        }
      </div>
    </ThemeProvider>
  );
}

export default App;
